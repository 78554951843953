/* eslint-disable react/jsx-props-no-spreading */

/**
 * @file Button component across the website
 * @author Alwyn Tan
 */

import styled from 'styled-components'
import React from 'react'
import PropTypes from 'prop-types'

const StyledButton = styled.button`
  outline: none;
  border: 0;
  border-radius: 100px;
  font-weight: 500;
  font-size: ${props => (props.small || props.tiny ? '0.8em' : '1.133em')};
  padding: 0 25px;
  height: ${props => {
    if (props.tiny) return '30px'
    if (props.small) return '40px'
    return '50px'
  }};
  position: ${props => (props.floating ? 'fixed' : 'unset')};
  width: ${props =>
    props.floating ? `${props.width || '300px'}` : `${props.width || 'unset'}`};
  bottom: ${props => (props.floating ? '40px' : 'unset')};
  left: ${props =>
    props.floating ? `calc(50% - ${props.width || '300px'}/2)` : 'unset'};
  z-index: ${props => (props.floating ? 100 : 0)};
  cursor: pointer;
  background-color: ${({ theme }) => theme.Accent};
  color: white;

  :disabled {
    opacity: 0.5;
  }
`

const Button = props => {
  return <StyledButton {...props} />
}

// other proptypes are put directly into the button
// if both tiny and small are passed in, the button will be rendered as tiny
Button.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object]),
  small: PropTypes.bool,
  tiny: PropTypes.bool,
  floating: PropTypes.bool, // floating = bottom of screen fixed
  width: PropTypes.string,
}

Button.defaultProps = {
  style: {},
  small: false,
  tiny: false,
  floating: false,
  width: '',
}

export default Button
