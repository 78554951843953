/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { motion } from 'framer-motion'

export const PureInput = styled.input`
  outline: none;
  border-radius: 10px;
  width: 315px;
  font-weight: 500;
  font-size: 0.933em;
  background-color: ${({ theme }) => theme.Tertiary};
  height: 40px;
  padding-left: 15px;
  border-style: solid;
  border-color: #d63031;
  border-width: ${props => (props.error ? 2 : 0)};
  max-width: 100%;
  color: inherit;

  ::-webkit-calendar-picker-indicator {
    padding-right: 10px;
  }

  ::placeholder {
    opacity: 0.6;
  }
`

const ErrorText = styled.h4`
  color: #d63031;
  padding-top: 5px;
  white-space: pre-wrap;
`

const Input = ({ error, isLoading, containerStyle, ...props }) => {
  return (
    <motion.div
      animate={{
        x: error ? [-10, 10, -10, 10, -10, 10, -10, 10, 0] : [0, 0],
        opacity: isLoading ? [0.5, 0.8] : 1,
      }}
      transition={{
        loop: isLoading && Infinity,
        duration: isLoading ? 0.8 : 0.5,
      }}
      style={{ maxWidth: '100%', ...containerStyle }}
    >
      <PureInput {...props} />
      {error && <ErrorText>{error}</ErrorText>}
    </motion.div>
  )
}

Input.propTypes = {
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  containerStyle: PropTypes.oneOfType([PropTypes.object]),
}

Input.defaultProps = {
  error: '',
  isLoading: false,
  containerStyle: {},
}

export default Input
